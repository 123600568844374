import { takeLatest, put, call } from 'redux-saga/effects';
import { DateTime } from 'luxon';
import {
  GET_ORDERS,
  UPDATE_ORDER,
  CREATE_ORDER,
  UPDATE_ORDER_ADDRESS,
  PERFORM_ORDER_ACTION,
  ADD_NOTE,
  PARTIALLY_REFUND_ORDER,
  REVIEW_ORDER,
  FIX_ORDER,
  CREATE_RMA,
  GET_RETURNS,
} from './actions';
import { normalizeOrdersResponse, normalizeReturnsResponse } from 'utils';
import { handleException } from 'store/general/sagas';

import { API } from 'axiosClient/config';
import { SET_LOADER } from 'store/general/actions';

import { postAPIData, putAPIData, getAPIData } from 'api/playosmo';

function* getOrders(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });

  try {
    const payload = {
      end_date: data.endDate
        ? parseInt(data.endDate)
        : parseInt(DateTime.now().toSeconds()),
      start_date: data.startDate ? parseInt(data.startDate) : null,
      order_number: data.orderID ? parseInt(data.orderID) : undefined,
      email: data.email,
      shipping_phone: data.phoneNumber,
      limit: data.limit,
      include_details: data.withDetails,
      first_name: data.firstName,
      last_name: data.lastName,
      shipping_country: data.country,
      website: data.website,
    };

    const response = yield call(postAPIData, API.orders.query, payload);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const orders = normalizeOrdersResponse(response.data.orders);

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(orders);
    }
  } catch (e) {
    console.log({ e });
    yield handleException(e, errorCallback);
  }
}

function* getReturns(action) {
  const { callback, errorCallback, cursor } = action.payload;

  try {
    const response = yield call(
      getAPIData,
      `${API.returns}?&limit=10&cursor=${cursor ? cursor : ''}`,
    );

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    const returns = normalizeReturnsResponse(response.data);

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(returns);
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* updateOrder(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const response = yield call(putAPIData, API.orders.update, data);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* createOrder(action) {
  const { data, callback, errorCallback } = action.payload;

  const payload = {
    ...data,
    email: data.email,
    description: data.description,
    source: data.source,
    coupon: data.coupon ? parseInt(data.coupon) : null,
    po_number: data.po_number,
    price_paid: data.price_paid ? parseFloat(data.price_paid) * 100 : null,
    shipping_cost: data.shipping_cost
      ? parseFloat(data.shipping_cost) * 100
      : null,
    country: data.country,
    zip: data.zip,
    send_emails: data.send_emails,
    shipping_method:
      data.shipping_method === 'default' ? '' : data.shipping_method,
  };
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const response = yield call(postAPIData, API.orders.create, payload);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(response.data.order_number);
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* updateOrderAddress(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const response = yield call(putAPIData, API.orders.addressUpdate, data);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    errorCallback(e?.response?.data);
  }
}

function* performOrderAction(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const payload = {
      apply_to_all: data.applyToAll,
      cancel: data.cancel,
      cancel_digital_redeem: data.cancelDigitalRedeem,
      cancel_subscriptions: data.cancelSubscriptions,
      cancel_shipment: data.cancelShipment,
      // generate_rma_link: false,
      order_number: data.orderId,
      refund: data.refund,
    };

    const response = yield call(postAPIData, API.orders.cancel, payload);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* addNoteToOrder(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const payload = {
      note: data.content,
      order_number: data.orderId,
    };

    const response = yield call(postAPIData, API.orders.addNote, payload);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* partiallyRefundOrder(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const payload = {
      campaign: data.campaign,
      order_number: data.orderId,
    };

    const response = yield call(postAPIData, API.orders.partialRefund, payload);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* reviewOrder(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const payload = {
      approve: data.approve,
      order_number: data.orderId,
      reject: data.reject,
    };

    const response = yield call(postAPIData, API.orders.review, payload);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* fixOrder(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const payload = {
      order_number: data.orderId,
    };

    const response = yield call(postAPIData, API.orders.inspect, payload);

    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(500);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback();
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

function* createRMA(action) {
  const { data, callback, errorCallback } = action.payload;
  yield put({
    type: SET_LOADER,
    payload: {
      isLoading: true,
    },
  });
  try {
    const payload = {
      order_id: data.orderId,
      cart: data.items,
    };

    const response = yield call(postAPIData, API.returns, payload);

    const normalizeRMAResponse = (res) => ({
      id: res?.id?.toString(),
      products: res.cart,
    });

    const formattedRes = normalizeRMAResponse(response.data);
    if (!response || response.status > 208) {
      if (errorCallback) {
        yield put({
          type: SET_LOADER,
          payload: {
            isLoading: false,
          },
        });
        errorCallback(response.error);
      }
      return;
    }

    yield put({
      type: SET_LOADER,
      payload: {
        isLoading: false,
      },
    });
    if (callback) {
      callback(formattedRes.id);
    }
  } catch (e) {
    yield handleException(e, errorCallback);
  }
}

export function* ordersSaga() {
  yield takeLatest(GET_ORDERS, getOrders);
  yield takeLatest(UPDATE_ORDER, updateOrder);
  yield takeLatest(CREATE_ORDER, createOrder);
  yield takeLatest(UPDATE_ORDER_ADDRESS, updateOrderAddress);
  yield takeLatest(PERFORM_ORDER_ACTION, performOrderAction);
  yield takeLatest(ADD_NOTE, addNoteToOrder);
  yield takeLatest(PARTIALLY_REFUND_ORDER, partiallyRefundOrder);
  yield takeLatest(REVIEW_ORDER, reviewOrder);
  yield takeLatest(FIX_ORDER, fixOrder);
  yield takeLatest(CREATE_RMA, createRMA);
  yield takeLatest(GET_RETURNS, getReturns);
}
